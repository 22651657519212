html {
  // font-size: 10px;
  min-height: 100vh;
  height: auto;

  * {
    font-family: 'Poppins', sans-serif;

    &:focus {
      outline: none !important;
    }
  }

  body,
  #__next {
    height: 100vh;
    margin: 0;

    a {
      color: inherit;
    }
  }

  .filter-group {
    padding: 0.3rem 0;

    .filter-actions {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      div {
        button+button {
          margin-left: 10px;
        }
      }
    }

    .filter-line {
      margin-bottom: 5px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;

      .delete-line {
        background-color: #9fa1a8;
        border: 0;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 30px;
        height: 30px;
        cursor: pointer;

        svg {
          color: white;
        }
      }

      .btn {
        margin: 0 5px 0 5px;
        font-weight: bold;
      }

      input {
        height: 30px;
        margin: 0 5px 0 5px;
        border: 1px solid #E96B83;
      }

      select {
        height: 35px;
        margin: 0 5px 0 5px;
        border: 1px solid #E96B83;
      }
    }

    .add-another {
      color: #757883;
      font-size: 0.7rem;
      align-items: center;
      display: flex;

      p {
        opacity: 0.7;
      }

      svg {
        padding-right: 5px;
      }
    }

    .add-actions {
      // padding-bottom: 50px;
    }

    .operands {
      display: flex;
      align-items: center;

      .or {
        background-color: #E96B83;
        color: white;
        border-radius: 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 30px;
        height: 30px;
        font-size: 0.7rem;
      }
    }

    .icon-in-button {
      padding-right: 5px;
    }

    .operand {
      position: relative;
      display: flex;

      .remove {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        text-align: center;
        border-radius: 50%;
        line-height: 18px;
        top: -15px;
        background-color: #E96B83;
        color: #fff;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}


.lp-h1 {
  padding: 0;
  margin: 0;
  font-family: 'acumin-pro-extra-condensed';
  font-style: normal;
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 3.5rem;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
}

.lp-h2 {
  padding: 0;
  margin: 0;
  font-family: 'acumin-pro-extra-condensed';
  font-style: normal;
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 0.3rem;
  text-transform: uppercase;

  &.error {
    text-align: center;
    color: #F29E5F;
  }
}

.lp-h3 {
  padding: 0;
  margin: 0;
  font-family: 'acumin-pro-extra-condensed';
  font-style: normal;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
}

.lp-body1 {
  padding: 0;
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.lp-body2 {
  padding: 0;
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.lp-body3 {
  padding: 0;
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.2rem;
}